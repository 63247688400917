import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ReferralTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const referrerId = searchParams.get('r');
    
    if (referrerId) {
      localStorage.setItem('referrerId', referrerId);
    }
  }, [location]);

  return null; // This component does not render anything
};

export default ReferralTracker;